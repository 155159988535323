<template>
  <div id="cms-entries">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Entries</h2></b-col>
        <b-col>
          <router-link class="link-color" :to="{ name: 'CmsEntryCreate' }" @click.prevent>
            <b-button type="submit" variant="success">Add Entry</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9 entry-table">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <b-table
                  v-else
                  striped
                  sort-by="orderByColumn"
                  sort-desc
                  :fields="fields"
                  :items="entries"
                  head-variant="light"
                  sticky-header="320px"
          >
            <template v-slot:cell(date)="date">
              <b class="text-info">
                <span :style="{ fontWeight: 'lighter', color: '#CBA661' }">{{ date.value }}</span>
              </b>
            </template>
            <template v-slot:cell(type_name)="type_name">
              <b class="text-info">
                <span :style="{ fontWeight: 'lighter', color: '#CBA661' }">{{ type_name.value }}</span>
              </b>
            </template>
            <template v-slot:cell(remark)="remark">
              <b class="text-info">
                <span :style="{ fontWeight: 'lighter', color: '#CBA661' }">{{ remark.value }}</span>
              </b>
            </template>
            <template v-slot:cell(amount)="amount">
              <b class="text-info">€{{ amount.value }}</b>
            </template>
            <template v-slot:cell(id)="id">
              <b-button-group size="small">
                <router-link class="link-color" :to="{ name: 'CmsEntryShow', params: { id: id.value }}" @click.prevent>
                  <b-icon icon="search" style="margin-right: 1em; color: #CBA661"></b-icon>
                </router-link>
                <router-link class="link-color" :to="{ name: 'CmsEntryUpdate', params: { id: id.value }}" @click.prevent>
                  <b-icon icon="pencil" style="margin-right: 1em; color: #CBA661"></b-icon>
                </router-link>
                <router-link class="link-color" :to="{ name: 'CmsEntryDelete', params: { id: id.value }}" @click.prevent>
                  <b-icon icon="x" style="margin-right: 1em; color: #CBA661"></b-icon>
                </router-link>
              </b-button-group>
            </template>
          </b-table>
          <b-pagination
                  v-if="!loading"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  size="sm"
                  @change="handlePageChange"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'

  export default {
    data() {
      return {
        loading: true,
        entryResponse: null,
        entries: [],
        entryTypes: [],
        currentPage: 1,
        totalRows: 1,
        perPage: 20,
        orderByColumn: 'date',
        orderBy: 'desc',
        fields: [
          {
            key: 'date',
            label: 'Date',
          },
          {
            key: 'type_name',
            label: 'Type',
          },
          {
            key: 'remark',
            label: 'Remark'
          },
          {
            key: 'amount',
            label: 'Amount'
          },
          {
            key: 'id',
            label: 'Actions',
          },
        ]
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get('https://batavia-backend.herokuapp.com/api/entry-type')
          .then(response => (
              this.entryTypes = response.data
          ))

      this.axios
          .get('https://batavia-backend.herokuapp.com/api/entry', {
            params: {
              pageNumber: 1,
              perPage: 20,
              orderByColumn: 'date',
              orderBy: 'desc'
            }
          })
          .then(response => (
              this.entryResponse = response.data,
              this.totalRows = this.entryResponse.total,
              this.perPage = this.entryResponse.per_page,
              this.currentPage = this.entryResponse.current_page,
              this.entries = JSON.parse(JSON.stringify(this.entryResponse.data)),
              this.entries.forEach(function (entry) {
                entry['type_name'] = this.getEntryTypeName(entry.type_id)
              }.bind(this))
          ))
          .finally(() => {
            this.loading = false
          })
    },
    methods: {
      getEntryTypeName(entryTypeId) {
        let entryTypeName = null

        this.entryTypes.forEach(function (entryType) {
          if (entryType['id'] === entryTypeId) {
            entryTypeName = entryType['name']
          }
        })

        return entryTypeName
      },
      handlePageChange(value) {
        this.loading = true
        this.currentPage = value

        this.axios
            .get('https://batavia-backend.herokuapp.com/api/entry', {
              params: {
                pageNumber: this.currentPage,
                perPage: this.perPage,
                orderByColumn: this.orderByColumn,
                orderBy: this.orderBy
              }
            })
            .then(response => (
                this.entryResponse = response.data,
                this.totalRows = this.entryResponse.total,
                this.perPage = this.entryResponse.per_page,
                this.entries = JSON.parse(JSON.stringify(this.entryResponse.data)),
                this.entries.forEach(function (entry) {
                  entry['type_name'] = this.getEntryTypeName(entry.type_id)
                }.bind(this))
            ))
            .finally(() => {
              this.$router.push({ name: 'CmsEntries' })
              this.loading = false
            })
      }
    }
  }
</script>

<style scoped>
  .entry-table {
    overflow: auto;
    height: 400px;
  }
</style>
